import { Reducer } from "redux";
import {
  AzureOfferActionTypes,
  AzureOfferState,
  AzureRegistrationInfo,
  AzureRegistrationResultCode,
  AzureRegistrationState,
  AzureTokenValidationCode,
  AzureTokenValidationResult,
  RegistrationError,
  RegistrationResponse
} from "./types";
import { AzureTransactionCode } from "src/components/offers/azurev2/AzureTransactionCodes";

const initialState: AzureRegistrationState = {
  loading: true
};

const reducer: Reducer<AzureRegistrationState> = (state = initialState, action) => {
  switch (action.type) {
    case AzureOfferActionTypes.FETCH_REGISTRATION_INFO: {
      return {
        ...state,
        registration: undefined
      };
    }
    case AzureOfferActionTypes.SET_REGISTRATION_INFO: {
      const registrationInfo: RegistrationResponse = action.payload;
      return {
        ...state,
        registration: registrationInfo,
        loading: false
      };
    }
    case AzureOfferActionTypes.SET_RENEWAL_STATUS: {
      const status: AzureTransactionCode = action.payload;
      return {
        ...state,
        azureRenewalStatus: status,
        loading: false
      };
    }
    case AzureOfferActionTypes.SET_REGISTRATION_ERROR: {
      const error: RegistrationError = action.payload;
      return { ...state, error: error, loading: false };
    }
    case AzureOfferActionTypes.INITIATE_REGISTRATION:
    case AzureOfferActionTypes.INITIATE_REGISTRATION_FOR_OFFER_ID:
    case AzureOfferActionTypes.INITIATE_RENEWAL:
    case AzureOfferActionTypes.RESET_REGISTRATION_FOR_OFFER_ID: {
      return { ...state, loading: true };
    }
    default: {
      return state;
    }
  }
};

export { reducer as azureOfferReducer };

