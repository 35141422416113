import '@ms-mwf/moray/dist/css/main.css';
import { connect } from 'react-redux';
import { isBingAdsOfferAvailable } from 'src/components/offers/bingAds/BingAdsUtils';
import Text from 'src/components/text/Text';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';
import { IFeatureFlagsState } from 'src/state/feature-flags';
import { INonprofitMiniProfile } from 'src/state/nonprofit';
import './FollowUsFooter.scss';
import { AzureExpirationStatus, RegistrationError, RegistrationResponse, RegistrationStatus } from 'src/state/offers/azure/types';
import { getAzureExpirationStatus } from '../offers/azurev2/Utils';

interface IFooterOwnProps {
  useExternalLinks?: boolean;
}

interface IFooterStateProps {
  azureError?: RegistrationError;
  featureFlags: IFeatureFlagsState;
  nonprofit: INonprofitMiniProfile;
}

type IFooterProps = IFooterOwnProps & IFooterStateProps;

function FollowUsFooter(props: IFooterProps) {
  const { azureError, featureFlags, nonprofit, useExternalLinks } = props;
  const localeContent = localeService.getLocaleContent();

  if (localeContent === null) {
    return null;
  }

  const facebookImageUrl = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/icons/facebook-white-bg.png`;
  const twitterImageUrl = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/icons/twitter-white-bg.png`;
  const linkedInImageUrl = `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/icons/linkedin-white-bg.png`;

  const products = getProductLinks(azureError, featureFlags, nonprofit, useExternalLinks ?? false);
  const resources = getResourceLinks(nonprofit, useExternalLinks ?? false);

  const followUsAriaLabel = localeService.getText('FollowUsSocialMedia', 'ariaLabel');

  const facebookArialabel = localeService.getText('RegistrationComplete', 'FacebookArialabel');
  const followOnFacebook = localeService.getText('ContactUsSocialMedia', 'FollowOnFacebook');
  const facebookLink = localeService.getText('Links', 'urlFacebook');

  const twitterArialabel = localeService.getText('RegistrationComplete', 'TwitterArialabel');
  const followOnTwitter = localeService.getText('ContactUsSocialMedia', 'FollowOnTwitter');
  const twitterUrl = localeService.getText('Links', 'urlTwitter');

  const linkedInAriaLabel = localeService.getText('RegistrationComplete', 'LinkedInAriaLabel');
  const followOnLinkedIn = localeService.getText('ContactUsSocialMedia', 'FollowOnLinkedIn');
  const linkedInUrl = localeService.getText('Links', 'urlLinkedIn');

  return (
    <div className="follow-us-footer">
      <div className="row">
        <section className="col-12 footer-margin" aria-label={followUsAriaLabel}>
          <h2 className="base footer-title align-middle mr-g d-inline">
            <Text category="FollowUsSocialMedia" id="FollowMicrosoft" />
          </h2>
          <ul className="list-inline mb-0 d-inline-block align-middle">
            <li className="list-inline-item mr-g">
              <a
                className="d-inline-block"
                aria-label={facebookArialabel}
                href={facebookLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebookImageUrl}
                  className="social-media-icon"
                  alt={facebookArialabel}
                  title={followOnFacebook}
                />
              </a>
            </li>
            <li className="list-inline-item mr-g">
              <a
                className="d-inline-block"
                aria-label={twitterArialabel}
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitterImageUrl}
                  className="social-media-icon"
                  alt={twitterArialabel}
                  title={followOnTwitter}
                />
              </a>
            </li>
            <li className="list-inline-item mr-g">
              <a
                className="d-inline-block"
                aria-label={linkedInAriaLabel}
                href={linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedInImageUrl}
                  className="social-media-icon"
                  alt={linkedInAriaLabel}
                  title={followOnLinkedIn}
                />
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div className="card">
        <div className="card-body ms-Grid-row footer-link-section row">
          <div className="ms-Grid-col col ms-sm12 ms-md6 footer-margin">
            <h6 className="footer-list-item">
              <Text category="ProductLinks" id="title" />
            </h6>
            <ul className="list-unstyled">
              {products.map((product, index) => (
                <li key={index} className="footer-list-item">
                  <a href={product.url} className="footer-list-link" aria-label={product.ariaLabel}>
                    {product.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="ms-Grid-col col ms-sm12 ms-md6 footer-margin">
            <h6 className="footer-list-item">
              <Text category="ResourceLinks" id="title" />
            </h6>
            <ul className="list-unstyled">
              {resources.map((resource, index) => (
                <li key={index} className="footer-list-item">
                  <a href={resource.url} className="footer-list-link" aria-label={resource.ariaLabel}>
                    {resource.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export interface IProductLink {
  title: string;
  url: string;
  ariaLabel: string;
}

export interface IProductLinkCategory {
  title: string;
  links: IProductLink[];
}

const getProductLinks = (
  azureError: RegistrationError | undefined,
  featureFlags: IFeatureFlagsState,
  nonprofit: INonprofitMiniProfile,
  useExternalLinks: boolean
): IProductLink[] => {
  if (!useExternalLinks && nonprofit !== null && nonprofit.status === 'Approved') {
    const productLinks = localeService.getCategoryContent('ProductLinks') as IProductLinkCategory;
    const links = productLinks ? productLinks.links : [];

    return links.filter((item) => {
      if (item.url === '/offers/bingads' && !isBingAdsOfferAvailable(nonprofit, featureFlags)) {
        return false;
      }
      if (item.url === '/offers/azure' && azureError?.status === 403) {
          return false;
      }
      return true;
    })
  } else {
    const productLinks = localeService.getCategoryContent('ProductExternalLinks');
    return productLinks ? productLinks.links : [];
  }
};

const getResourceLinks = (nonprofit: INonprofitMiniProfile, useExternalLinks: boolean): IProductLink[] => {
  if (!useExternalLinks && nonprofit !== null && nonprofit.status === 'Approved') {
    const resourceLinks = localeService.getCategoryContent('ResourceLinks');
    return resourceLinks ? resourceLinks.links : [];
  } else {
    const resourceLinks = localeService.getCategoryContent('ResourceExternalLinks');
    return resourceLinks ? resourceLinks.links : [];
  }
};

const mapStateToProps = ({ offers, nonprofit, flags }: IApplicationState) => ({
  nonprofit: nonprofit.miniProfile,
  azureError: offers.azure.error,
  featureFlags: flags
});

export default connect(mapStateToProps)(FollowUsFooter);
