import '@ms-mwf/moray/dist/css/main.css';
import htmlParser from 'html-react-parser';
import localeService from 'src/dataServices/LocaleService';
import useM365CopilotDiscountOffer from './M365CopilotDiscountOffer';

const CopilotInfoSection = () => {
  const { isM365CopilotDiscountOfferAvaliable } = useM365CopilotDiscountOffer();
  return (
    <div className="bg-gray-100 plan-section-container">
      <div className="bg-white p-4">
        <p>
          {htmlParser(
            localeService.getText(
              'Microsoft365V2',
              isM365CopilotDiscountOfferAvaliable ? 'CopilotInfoDescription1_1' : 'CopilotInfoDescription1'
            ) ?? ''
          )}
        </p>
        <p>
          {htmlParser(
            localeService.getText(
              'Microsoft365V2',
              isM365CopilotDiscountOfferAvaliable ? 'CopilotInfoDescription2_1' : 'CopilotInfoDescription2'
            ) ?? ''
          )}
        </p>
        {isM365CopilotDiscountOfferAvaliable && (
          <p>{htmlParser(localeService.getText('Microsoft365V2', 'CopilotInfoDescription2_11') ?? '')}</p>
        )}
        <p>
          {htmlParser(
            localeService.getText(
              'Microsoft365V2',
              isM365CopilotDiscountOfferAvaliable ? 'CopilotInfoDescription3_1' : 'CopilotInfoDescription3'
            ) ?? ''
          )}
        </p>
      </div>
    </div>
  );
};

export default CopilotInfoSection;
