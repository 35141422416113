import { action } from "typesafe-actions";
import {
  AzureFormSubmission,
  AzureOfferActionTypes,
  AzureRegistrationInfo,
  AzureRegistrationResultCode,
  AzureTokenValidationResult,
  RegistrationError,
  RegistrationResponse
} from "./types";
import { AzureTransactionCode } from "src/components/offers/azurev2/AzureTransactionCodes";

export const fetchAzureRegistrationInfo = () => action(AzureOfferActionTypes.FETCH_REGISTRATION_INFO);
export const setAzureRegistrationInfo = (info: RegistrationResponse | undefined | null) =>
  action(AzureOfferActionTypes.SET_REGISTRATION_INFO, info);
export const setAzureRegistrationError = (error: any) => action(AzureOfferActionTypes.SET_REGISTRATION_ERROR, error);
export const setAzureRenewalStatus = (status: AzureTransactionCode | undefined | null) =>
  action(AzureOfferActionTypes.SET_RENEWAL_STATUS, status);

export const initiateAzureRegistration = (values: AzureFormSubmission) =>
  action(AzureOfferActionTypes.INITIATE_REGISTRATION, values);
export const initiateAzureRegistrationForOfferId = (offerId: string) =>
  action(AzureOfferActionTypes.INITIATE_REGISTRATION_FOR_OFFER_ID, offerId);
export const resetAzureRegistrationForOfferId = (offerId: string) =>
  action(AzureOfferActionTypes.RESET_REGISTRATION_FOR_OFFER_ID, offerId);

export const setAzureRegistrationResult = (result: AzureRegistrationResultCode) =>
  action(AzureOfferActionTypes.SET_REGISTRATION_RESULT, result);
export const initiateAzureRenewal = (token: string) => action(AzureOfferActionTypes.INITIATE_RENEWAL, token);
export const setAzureTokenValidationResult = (result: AzureTokenValidationResult) =>
  action(AzureOfferActionTypes.SET_TOKEN_RESULT, result);
