import { scrollOptions } from 'src/common/utilities/Utilities';
import { IResizableImage } from 'src/components/moray/ResizeableImage';
import localeService from 'src/dataServices/LocaleService';
import HighlightBanner, { IHighlightBanner } from '../shared/HighlightBanner';
import HighlightBannerCarousel from '../shared/HighlightBannerCarousel';
import useM365CopilotDiscountOffer from './M365CopilotDiscountOffer';
import useM365CopilotOfferUpdate from './useM365CopilotOfferUpdate';

interface IM365TopBannerProps {
  stepsElement: HTMLElement | null;
  offersElement: HTMLElement | null;
  setRef?: (el: HTMLElement | null) => void;
}

const M365TopBanner = (props: IM365TopBannerProps) => {
  const { isM365CopilotOfferAvailable } = useM365CopilotOfferUpdate();
  const { isM365CopilotDiscountOfferAvaliable } = useM365CopilotDiscountOffer();

  const resizableImageProps: IResizableImage[] = [
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-HighlightBanner1-540.png`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('Microsoft365V2', 'HighlightBannerTitle1'),
      fileSize1920: 'M365-HighlightBanner1-1920.png',
      fileSize1400: 'M365-HighlightBanner1-1400.png',
      fileSize1084: 'M365-HighlightBanner1-1084.png',
      fileSize860: 'M365-HighlightBanner1-860.png',
      fileSize540: 'M365-HighlightBanner1-540.png',
      fileSize360: 'M365-HighlightBanner1-360.png'
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-HighlightBanner2-540.png`,
      imageClassName: 'img-fill-container card-img',
      imageAlt: localeService.getText('Microsoft365V2', 'HighlightBannerTitle2'),
      fileSize1920: 'M365-HighlightBanner2-1920.png',
      fileSize1400: 'M365-HighlightBanner2-1400.png',
      fileSize1084: 'M365-HighlightBanner2-1084.png',
      fileSize860: 'M365-HighlightBanner2-860.png',
      fileSize540: 'M365-HighlightBanner2-540.png',
      fileSize360: 'M365-HighlightBanner2-360.png'
    },
    {
      root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
      imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-HighlightBanner3.jpg`,
      imageClassName: 'card-img w-100',
      imageAlt: localeService.getText('Microsoft365V2', 'HighlightBannerImgAlt3')
    }
  ];

  const children: React.ReactElement<IHighlightBanner>[] = [
    <HighlightBanner
      titleText={localeService.getText('Microsoft365V2', 'HighlightBannerTitle3')}
      descriptionText={localeService.getText(
        'Microsoft365V2',
        isM365CopilotDiscountOfferAvaliable ? 'HighlightBannerDescription3_1' : 'HighlightBannerDescription3'
      )}
      buttonText={localeService.getText('Microsoft365V2', 'HighlightBannerLearnMore')}
      buttonOnClick={() => props.offersElement?.scrollIntoView(scrollOptions)}
      buttonAriaLabel={localeService.getText('Microsoft365V2', 'HighlightBannerLearnMoreAriaLabel3')}
      resizableImageProps={resizableImageProps[2]}
    />,
    <HighlightBanner
      titleText={localeService.getText('Microsoft365V2', 'HighlightBannerTitle1')}
      descriptionText={localeService.getText('Microsoft365V2', 'HighlightBannerDescription1')}
      buttonText={localeService.getText('Microsoft365V2', 'HighlightBannerLearnMore')}
      buttonOnClick={() => {
        if (props.stepsElement !== null) {
          props.stepsElement.scrollIntoView(scrollOptions);
        }
      }}
      buttonAriaLabel={localeService.getText('Microsoft365V2', 'HighlightBannerLearnMoreAriaLabel1')}
      resizableImageProps={resizableImageProps[0]}
    />
  ];

  if (!isM365CopilotOfferAvailable) {
    children.shift();
  }

  return (
    <div
      id="m365-banner"
      className="m365-banner"
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <HighlightBannerCarousel>{children}</HighlightBannerCarousel>
    </div>
  );
};

export default M365TopBanner;
