import { IApiResponse } from "src/common/ApiResponse";
import { httpRequest } from "src/common/HttpRequest";
import {
  AzureFormSubmission,
  AzureRegistrationInfo,
  AzureRegistrationResult,
  AzureTokenValidationResult,
  RegistrationResponse
} from "src/state/offers/azure/types";
import authenticationService from "./AuthenticationService";
import { callApi, extractData } from "./SharedServiceFunctions";

export class AzureRegistrationService {
  private API_URL = process.env.REACT_APP_INTERNAL_API_BASEURL;

  public async getActiveRegistrations(): Promise<IApiResponse<RegistrationResponse>> {
    return await callApi<RegistrationResponse>(`${this.API_URL}/azure/registrations/latest`);
  }

  public async createRegistration(formValues: AzureFormSubmission): Promise<IApiResponse<RegistrationResponse>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/azure/registrations`,
      token,
      JSON.stringify(formValues),
      "POST"
    );
    return response.ok
    ? { status: response.status, value: await extractData<RegistrationResponse>(response) }
    : { status: response.status, error: await extractData(response) };
  }

  public async createRegistrationForOfferId(offerId: string): Promise<IApiResponse<RegistrationResponse>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/azure/registrations/${offerId}`, token, null, "POST");
    return response.ok
    ? { status: response.status, value: await extractData<RegistrationResponse>(response) }
    : { status: response.status, error: await extractData(response) };
  }

  public async resetRegistrationForOfferId(offerId: string): Promise<IApiResponse<RegistrationResponse>> {
    const token = await authenticationService.getToken();
    const response = await httpRequest(`${this.API_URL}/azure/registrations/reset/${offerId}`, token, null, "PUT");
    return response.ok
    ? { status: response.status, value: await extractData<RegistrationResponse>(response) }
    : { status: response.status, error: await extractData(response) };
  }

  public async renewRegistration(token: string): Promise<IApiResponse<RegistrationResponse>> {
    const authToken = await authenticationService.getToken();
    const response = await httpRequest(
      `${this.API_URL}/azure/registrations/renew`,
      authToken,
      null,
      "PUT"
    );
    return response.ok
    ? { status: response.status, value: await extractData<RegistrationResponse>(response) }
    : { status: response.status, error: await extractData(response) };
  }
}

const azureRegistrationService = new AzureRegistrationService();
export default azureRegistrationService;
