import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IResizableImage } from "src/components/moray/ResizeableImage";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { OfferId } from "src/state/nonprofit/types";
import { initiateAzureRegistrationForOfferId } from "src/state/offers/azure/actions";
import HighlightBanner from "../shared/HighlightBanner";
import { IFeatureFlagsState } from "src/state/feature-flags";

interface IPropsFromState {
  isRegistering: boolean;
  featureFlags: IFeatureFlagsState;
}

interface IPropsFromDispatch {
  initiateAzureRegistrationForOfferId?: typeof initiateAzureRegistrationForOfferId;
}

export type IAzureUnclaimedBannerProps = IPropsFromState & IPropsFromDispatch;

export const AzureUnclaimedBanner = ({
  isRegistering,
  featureFlags,
  initiateAzureRegistrationForOfferId
}: IAzureUnclaimedBannerProps) => {

  const resizableImageProps: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/azure-unclaimed-banner-540.png`,
    imageClassName: "img-fill-container card-img",
    imageAlt: localeService.getText("AzureV2", "GrantUnclaimedBannerTitle"),
    fileSize1920: "azure-unclaimed-banner-1920.png",
    fileSize1400: "azure-unclaimed-banner-1400.png",
    fileSize1084: "azure-unclaimed-banner-1084.png",
    fileSize860: "azure-unclaimed-banner-860.png",
    fileSize540: "azure-unclaimed-banner-540.png",
    fileSize360: "azure-unclaimed-banner-360.png"
  };

  return (
      <HighlightBanner
      titleText={localeService.getText("AzureV2", "GrantUnclaimedBannerTitleNew")}
      descriptionText={localeService.getText("AzureV2", "GrantUnclaimedBannerDescriptionNew")}
      buttonText={localeService.getText("AzureV2", "GrantClaimNow")}
      buttonOnClick={() => initiateAzureRegistrationForOfferId?.(OfferId.azure)}
      buttonAriaLabel={localeService.getText("AzureV2", "GrantClaimNow")}
      resizableImageProps={resizableImageProps}
      isLoading={isRegistering}
    />
  );
};

const mapStateToProps = ({ offers, flags }: IApplicationState) => ({
  isRegistering: offers.azure.loading,
  featureFlags: flags
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  initiateAzureRegistrationForOfferId: (offerId: string) => dispatch(initiateAzureRegistrationForOfferId(offerId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AzureUnclaimedBanner);
