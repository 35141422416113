import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import FollowUsFooter from "src/components/followUsFooter/FollowUsFooter";
import localeService from "src/dataServices/LocaleService";
import { IApplicationState } from "src/state";
import { IFeatureFlagsState } from "src/state/feature-flags";
import ActionStepCard from "../shared/ActionStepCard";
import ActionSteps from "../shared/ActionSteps";
import { ContactUsSection } from "../shared/ContactUsSection";
import InfoSection from "../shared/InfoSection";
import NavigationBar from "../shared/NavigationBar";
import PartnersBanner from "../shared/PartnersBanner";
import PricingDisclaimer from "../shared/PricingDisclaimer";
import StandardTrainingSection from "../shared/StandardTrainingSection";
import AzureGrantDisclaimer from "./AzureGrantDisclaimer";
import AzureIntroBanner from "./AzureIntroBanner";
import AzureUnclaimedBanner from "./AzureUnclaimedBanner";
import { RegistrationError, RegistrationStatus } from "src/state/offers/azure/types";
import AzureStep1V2 from "./AzureStep1V2";
import AzureStatusBannerV2 from "./AzureStatusBannerV2";
import { getLocalizedPath } from "src/components/utilities/Localization/Utils";
import { Redirect } from "react-router-dom";

interface IPropsFromState {
  accountOwnerLiveId?: string;
  registrationError?: RegistrationError;
  registrationStatus?: RegistrationStatus;
  loading: boolean;
}

export type AzureProps = IPropsFromState;

export const AzureV2: React.FunctionComponent<AzureProps> = props => {
  const { accountOwnerLiveId, registrationError, registrationStatus, loading } = props;
  const [offerSection, setOfferSection] = useState<HTMLElement | null>(null);
  const [trainingSection, setTrainingSection] = useState<HTMLElement | null>(null);

  const step2ImagePath = "hub-redesign/ActionStep_2_3x.png";
  const step3ImagePath = "hub-redesign/ActionStep_3_3x.png";

  if (loading) {
    return (
      <section>
        <Spinner
          size={SpinnerSize.large}
          label={localeService.getText("Shared", "SpinnerPleaseWait")}
          ariaLive="assertive"
        />
      </section>
    )
  }

  if (registrationError?.status === 403) {
    return <Redirect to={getLocalizedPath('/')} />
  }

  return (
    <section>
      <AzureStatusBannerV2 />

      {registrationError ? (
        <p>An error occurred when getting your existing registrations. Please refresh the page and try again.</p>
      ) : null}

      {registrationStatus === RegistrationStatus.CanClaim && (
        <NavigationBar offersElement={offerSection} trainingElement={trainingSection} />
      )}
      {registrationStatus === RegistrationStatus.CanClaim && <AzureUnclaimedBanner />}
      {registrationStatus === RegistrationStatus.CanClaim && (
        <InfoSection
          category="AzureV2"
          imagePath1="optimize-costs.png"
          title1="OptimizeCosts"
          description1="OptimizeCostsDetail"
          imagePath2="work-from-anywhere.png"
          title2="WorkFromAnywhere"
          description2="WorkFromAnywhereDetail"
          imagePath3="stay-secure.png"
          title3="StaySecure"
          description3="StaySecureDetail"
        />
      )}
      {registrationStatus === RegistrationStatus.CanClaim && <AzureIntroBanner setRef={setOfferSection} />}
      {registrationStatus !== RegistrationStatus.NotEligible && (
        <ActionSteps
          stepsHeader={localeService.getText("AzureV2", "AzureClaimActionBannerText")}
          step1Card={
            <AzureStep1V2 />
          }
          step2Card={
            <ActionStepCard
              stepIconImagePath={step2ImagePath}
              cardTextCategory="AzureV2"
              cardTitle="SetupGrantActionStepTitle"
              cardDescription="SetupGrantActionStepDescription"
              ctaText="WatchNowText"
              onClickLink="https://aka.ms/Nonprofit_AzureCredits"
            />
          }
          step3Card={
            <ActionStepCard
              stepIconImagePath={step3ImagePath}
              cardTextCategory="AzureV2"
              cardTitle="ConnectSpecialistActionStepTitle"
              cardDescription="ConnectSpecialistActionStepDescription"
              ctaText="ScheduleNowText"
              onClickLink="https://nonprofits.tsi.microsoft.com/Azure-Onboarding-Concierge-and-Success-Center/"
            />
          }
        />
      )}
      {registrationStatus !== undefined && <AzureGrantDisclaimer />}
      <PartnersBanner />
      <StandardTrainingSection setRef={setTrainingSection} />
      <ContactUsSection />
      <PricingDisclaimer pricingDisclaimer1="PricingDisclaimerCommonParagraph" pricingDisclaimer2="AzureDisclaimer" />
      <FollowUsFooter />
    </section>
  );
};

const mapStateToProps = ({ offers }: IApplicationState) => ({
  accountOwnerLiveId: offers.azure.registration?.claimedByEmail,
  registrationError: offers.azure.error,
  registrationStatus: offers.azure.registration?.registrationStatus,
  loading: offers.azure.loading
});

export default connect(
  mapStateToProps,
  null
)(AzureV2);
