import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IApplicationState } from "src/state";
import { OfferId } from "src/state/nonprofit/types";
import { initiateAzureRegistrationForOfferId, initiateAzureRenewal } from "src/state/offers/azure/actions";
import {
  RegistrationResponse,
  RegistrationStatus
} from "src/state/offers/azure/types";
import ActionStepCard from "../shared/ActionStepCard";
import { IFeatureFlagsState } from "src/state/feature-flags";

interface IPropsFromState {
  azureRegistration?: RegistrationResponse
  featureFlags: IFeatureFlagsState;
}

interface IPropsFromDispatch {
  initiateAzureRegistrationForOfferId?: typeof initiateAzureRegistrationForOfferId;
  initiateAzureRenewal: typeof initiateAzureRenewal;
}

const step1IconPath = "hub-redesign/ActionStep_1_3x.png";

export type IAzureStep1V2Props = IPropsFromState & IPropsFromDispatch;

export const AzureStep1V2: React.FunctionComponent<IAzureStep1V2Props> = props => {
  const {
    azureRegistration,
    initiateAzureRegistrationForOfferId,
    initiateAzureRenewal,
    featureFlags
  } = props;
  const { registrationStatus } = azureRegistration || {};
  switch (registrationStatus) {
    case RegistrationStatus.CanClaim:
        return (
            <div>
              <div id="newclaim" aria-live="polite">
                <ActionStepCard
                    stepIconImagePath={step1IconPath}
                    cardTextCategory="AzureV2"
                    cardTitle="GrantClaimActionStepTitle"
                    cardDescription={"GrantClaimActionStepDescriptionNew"}
                    ctaText="GrantClaimNow"
                    onClickAction={() => initiateAzureRegistrationForOfferId?.(OfferId.azure)}
                    maxReloadsAfterSpinner={1}
                />
              </div>
            </div>
        );
    case RegistrationStatus.CanRenew:
        return (
            <div>
              <div id="renewclaim" aria-live="polite">
                <ActionStepCard
                  stepIconImagePath={step1IconPath}
                  cardTextCategory="AzureV2"
                  cardTitle="GrantRenewActionStepTitle"
                  cardDescription={"GrantRenewActionStepDescriptionNew"}
                  ctaText="GrantRenewNow"
                  onClickAction={() => initiateAzureRenewal("")}
                  maxReloadsAfterSpinner={1}
                  ctaDisabled={false}
                />
              </div>
            </div>
        );
    case RegistrationStatus.LegacyUpgrade:
        return (
            <div>
                <div id="renewclaim" aria-live="polite">
                    <ActionStepCard
                    stepIconImagePath={step1IconPath}
                    cardTextCategory="AzureV2"
                    cardTitle="GrantClaimActionStepTitle"
                    cardDescription={"GrantRenewActionStepDescriptionLegacyUpgrade"}
                    ctaText="GrantRenewNow"
                    onClickAction={() => initiateAzureRenewal("")}
                    maxReloadsAfterSpinner={1}
                    ctaDisabled={false}
                    />
                </div>
            </div>
        );
    case RegistrationStatus.UnderReview:
        return (
            <div>
                <div aria-live="polite">
                    <ActionStepCard
                    stepIconImagePath={step1IconPath}
                    cardTextCategory="AzureV2"
                    cardTitle="GrantClaimActionStepTitle"
                    cardDescription={"GrantClaimActionStepDescriptionNew"}
                    ctaText="GrantClaimNow"
                    onClickAction={() => initiateAzureRegistrationForOfferId?.(OfferId.azure)}
                    ctaDisabled={true}
                    />
                </div>
            </div>
        );
    case RegistrationStatus.NotEligible:
        return (
            <div>
              <div id="newclaim" aria-live="polite">
                <ActionStepCard
                    stepIconImagePath={step1IconPath}
                    cardTextCategory="AzureV2"
                    cardTitle="GrantClaimActionStepTitle"
                    cardDescription={"GrantClaimActionStepDescriptionNew"}
                    ctaText="GrantClaimNow"
                    onClickAction={() => initiateAzureRegistrationForOfferId?.(OfferId.azure)}
                    maxReloadsAfterSpinner={1}
                    ctaDisabled={true}
                />
              </div>
            </div>
        );
    default:
        return null
  };
};

const mapStateToProps = ({ offers, flags }: IApplicationState) => ({
  azureRegistration: offers.azure.registration,
  featureFlags: flags
});

const mapDispatchToProps: (dispatch: Dispatch) => IPropsFromDispatch = dispatch => ({
  initiateAzureRegistrationForOfferId: (offerId: string) => dispatch(initiateAzureRegistrationForOfferId(offerId)),
  initiateAzureRenewal: (offerId: string) => dispatch(initiateAzureRenewal(offerId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AzureStep1V2);
