import { connect } from 'react-redux';
import localeEnabledForV2, { LocaleConfigStrings } from 'src/common/localeEnabledForV2';
import MorayContentCardsSneakPeek, {
  IContentCard,
  MorayCardImageClassName
} from 'src/components/moray/MorayContentCardsSneakPeek';
import { IResizableImage } from 'src/components/moray/ResizeableImage';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';
import { IFeatureFlagsState } from 'src/state/feature-flags';
import CarouselOffer from '../shared/CarouselOffer';
import useM365CopilotDiscountOffer from './M365CopilotDiscountOffer';
import useM365CopilotOfferUpdate from './useM365CopilotOfferUpdate';

interface IM365OffersOwnProps {
  setRef?: (el: HTMLElement | null) => void;
}

interface IM365OffersStateProps {
  featureFlags: IFeatureFlagsState;
}

type IM365OffersProps = IM365OffersOwnProps & IM365OffersStateProps;

const M365Offers = (props: IM365OffersProps) => {
  const { isM365CopilotOfferAvailable } = useM365CopilotOfferUpdate();
  const { isM365CopilotDiscountOfferAvaliable } = useM365CopilotDiscountOffer();

  const copilotOfferContents = (
    <CarouselOffer
      category="Microsoft365V2"
      descriptions={[
        isM365CopilotDiscountOfferAvaliable ? 'CopilotOfferDescription1_1' : 'CopilotOfferDescription1',
        isM365CopilotDiscountOfferAvaliable ? 'CopilotOfferDescription2_1' : 'CopilotOfferDescription2'
      ]}
    />
  );

  const offerBingAdsContents = <CarouselOffer category="Microsoft365V2" descriptions={['OfferBingAdsDescription1']} />;

  const getOfferContents = (descriptions: string[][]) =>
    descriptions.map((desc, index) => <CarouselOffer key={index} category="Microsoft365V2" descriptions={desc} />);

  const offerDescriptions = isM365CopilotDiscountOfferAvaliable
    ? [
        ['OfferOneDescription1', 'OfferOneDescription2', 'OfferOneDescription3'],
        ['OfferTwoDescription1', 'OfferTwoDescription2', 'OfferTwoDescription3'],
        ['OfferThreeDescription1', 'OfferThreeDescription2', 'OfferThreeDescription3']
      ]
    : [
        ['OfferOneDescription1', 'OfferOneDescription2'],
        ['OfferTwoDescription1', 'OfferTwoDescription2'],
        ['OfferThreeDescription1', 'OfferThreeDescription2']
      ];

  const [offerOneContents, offerTwoContents, offerThreeContents] = getOfferContents(offerDescriptions);

  const copilotOfferImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-Offer-Copilot.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Microsoft365V2', 'CopilotOfferImageAlt')
  };

  const offerBingAdsImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-Offer-BingAds-540.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Microsoft365V2', 'OfferBingAdsImageAlt'),
    fileSize1920: 'M365-Offer-BingAds-1920.jpg',
    fileSize1400: 'M365-Offer-BingAds-1400.jpg',
    fileSize1084: 'M365-Offer-BingAds-1084.jpg',
    fileSize860: 'M365-Offer-BingAds-860.jpg',
    fileSize540: 'M365-Offer-BingAds-540.jpg',
    fileSize360: 'M365-Offer-BingAds-360.jpg'
  };

  const offerOneImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-Offer1-540.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Microsoft365V2', 'OfferOneImageAlt'),
    fileSize1920: 'M365-Offer1-1920.jpg',
    fileSize1400: 'M365-Offer1-1400.jpg',
    fileSize1084: 'M365-Offer1-1084.jpg',
    fileSize860: 'M365-Offer1-860.jpg',
    fileSize540: 'M365-Offer1-540.jpg',
    fileSize360: 'M365-Offer1-360.jpg'
  };

  const offerTwoImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-Offer2-540.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Microsoft365V2', 'OfferTwoImageAlt'),
    fileSize1920: 'M365-Offer2-1920.jpg',
    fileSize1400: 'M365-Offer2-1400.jpg',
    fileSize1084: 'M365-Offer2-1084.jpg',
    fileSize860: 'M365-Offer2-860.jpg',
    fileSize540: 'M365-Offer2-540.jpg',
    fileSize360: 'M365-Offer2-360.jpg'
  };

  const offerThreeImageSizes: IResizableImage = {
    root: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}`,
    imageSrc: `${process.env.REACT_APP_HUB_IMAGES_CDN_URL}/M365-Offer3-540.jpg`,
    imageClassName: MorayCardImageClassName,
    imageAlt: localeService.getText('Microsoft365V2', 'OfferThreeImageAlt'),
    fileSize1920: 'M365-Offer3-1920.jpg',
    fileSize1400: 'M365-Offer3-1400.jpg',
    fileSize1084: 'M365-Offer3-1084.jpg',
    fileSize860: 'M365-Offer3-860.jpg',
    fileSize540: 'M365-Offer3-540.jpg',
    fileSize360: 'M365-Offer3-360.jpg'
  };

  let offers: IContentCard[] = [];

  if (isM365CopilotOfferAvailable) {
    offers.push({
      title: localeService.getText('Microsoft365V2', 'CopilotOfferTitle'),
      contents: copilotOfferContents,
      sectionAria: localeService.getText('Microsoft365V2', 'CopilotOfferSectionAria'),
      imgSizes: copilotOfferImageSizes,
      actionText: localeService.getText('Microsoft365V2', 'CopilotOfferCtaText'),
      onClickAria: localeService.getText('Microsoft365V2', 'CopilotOfferCtaAria'),
      onClickAction: () => {
        window.open(
          localeService.getText(
            'Microsoft365V2',
            isM365CopilotDiscountOfferAvaliable ? 'CopilotOfferCtaLink1' : 'CopilotOfferCtaLink'
          )
        );
      }
    });
  }

  if (props.featureFlags.BingAdsOfferEnabled && localeEnabledForV2(LocaleConfigStrings.BingAds)) {
    offers.push({
      title: localeService.getText('Microsoft365V2', 'OfferBingAdsTitle1'),
      contents: offerBingAdsContents,
      sectionAria: localeService.getText('Microsoft365V2', 'OfferBingAdsSectionAria'),
      imgSizes: offerBingAdsImageSizes,
      actionText: localeService.getText('Microsoft365V2', 'OfferBingAdsActionText'),
      onClickAria: localeService.getText('Microsoft365V2', 'OfferBingAdsActionAria'),
      onClickAction: () => {
        window.open(localeService.getText('Urls', 'BingAdsLink'), '_self');
      },
      isNew: true
    });
  }

  offers = offers.concat([
    {
      title: localeService.getText('Microsoft365V2', 'OfferTitle1'),
      contents: offerOneContents,
      sectionAria: localeService.getText('Microsoft365V2', 'OfferOneSectionAria'),
      imgSizes: offerOneImageSizes,
      actionText: localeService.getText('Microsoft365V2', 'OfferActionText'),
      onClickAria: localeService.getText('Microsoft365V2', 'OfferActionAria'),
      onClickAction: () => {
        window.open(localeService.getText('Urls', 'BusinessPremiumBuyNow'));
      }
    },
    {
      title: localeService.getText('Microsoft365V2', 'OfferTitle2'),
      contents: offerTwoContents,
      sectionAria: localeService.getText('Microsoft365V2', 'OfferTwoSectionAria'),
      imgSizes: offerTwoImageSizes,
      actionText: localeService.getText('Microsoft365V2', 'OfferActionText'),
      onClickAria: localeService.getText('Microsoft365V2', 'OfferActionAria'),
      onClickAction: () => {
        window.open(localeService.getText('Urls', 'BusinessVoiceBuyNow'));
      }
    },
    {
      title: localeService.getText('Microsoft365V2', 'OfferTitle3'),
      contents: offerThreeContents,
      sectionAria: localeService.getText('Microsoft365V2', 'OfferThreeSectionAria'),
      imgSizes: offerThreeImageSizes,
      actionText: localeService.getText('Microsoft365V2', 'OfferActionText'),
      onClickAria: localeService.getText('Microsoft365V2', 'OfferActionAria'),
      onClickAction: () => {
        window.open(localeService.getText('Urls', 'BusinessBasicBuyNow'));
      }
    }
  ]);

  return (
    <div
      id="offers"
      className="current-offers"
      ref={(el) => {
        if (props.setRef !== undefined) {
          props.setRef(el);
        }
      }}
    >
      <h2 className="title h2">{localeService.getText('Microsoft365V2', 'CurrentOffersTitle')}</h2>
      <MorayContentCardsSneakPeek
        contentCards={offers}
        ariaLabel={localeService.getText('Microsoft365V2', 'CurrentOffersTitle')}
      />
    </div>
  );
};

const mapStateToProps = ({ flags }: IApplicationState) => ({
  featureFlags: flags
});

export default connect(mapStateToProps)(M365Offers);
