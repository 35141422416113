import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../state';

interface IM365CopilotDiscountOffer {
  isM365CopilotDiscountOfferAvaliable: boolean;
}

const useM365CopilotDiscountOffer = (): IM365CopilotDiscountOffer => {
  const featureFlags = useSelector((state: IApplicationState) => state.flags);
  const isM365CopilotDiscountOfferAvaliable = featureFlags.M365CopilotDiscountOfferEnabled;
  return {
    isM365CopilotDiscountOfferAvaliable
  };
};

export default useM365CopilotDiscountOffer;
