import { AzureTransactionCode } from "src/components/offers/azurev2/AzureTransactionCodes";

export const enum AzureOfferActionTypes {
  FETCH_REGISTRATION_INFO = "@@offers/azure/FETCH_REGISTRATION_INFO",
  SET_REGISTRATION_INFO = "@@offers/azure/SET_REGISTRATION_INFO",
  SET_RENEWAL_STATUS = "@@offers/azure/SET_RENEWAL_STATUS",
  SET_REGISTRATION_ERROR = "@@offers/azure/SET_REGISTRATION_INFO_ERROR",
  INITIATE_REGISTRATION = "@@offers/azure/INITIATE_REGISTRATION",
  INITIATE_REGISTRATION_FOR_OFFER_ID = "@@offers/azure/INITIATE_REGISTRATION_FOR_OFFER_ID",
  RESET_REGISTRATION_FOR_OFFER_ID = "@@offers/azure/RESET_REGISTRATION_FOR_OFFER_ID",
  SET_REGISTRATION_RESULT = "@@offers/azure/SET_REGISTRATION_RESULT",
  INITIATE_RENEWAL = "@@offers/azure/INITIATE_RENEWAL",
  SET_TOKEN_RESULT = "@@offers/azure/SET_TOKEN_RESULT"
}

export const enum AzureRegistrationResultCode {
  Error = 0,
  Ok,
  DuplicateEmail,
  InvalidEmail,
  CorporateEmail,
  EnterpriseEmail,
  AgentError,
  AgentValidationUnderReview,
  AgentValidationError,
  TenantError
}

export const enum AzureTokenValidationCode {
  InitialCode = 0,
  Invalid = 1,
  Inactive,
  Active,
  Error
}

export interface AzureFormSubmission {
  accountOwnerLiveId: string;
  customerNotificationEmails: string;
}

export interface AzureRegistrationInfo {
  accountOwnerLiveId: string;
  startDate: string;
  endDate: string;
  offerId: string;
  statusId: number;
}

export interface AzureRegistrationResult {
  isSuccessful: boolean;
  message: string;
  code: AzureRegistrationResultCode;
  registration?: AzureRegistrationInfo;
}

export interface AzureTokenValidationResult {
  code: AzureTokenValidationCode;
  registration?: AzureRegistrationInfo;
}

export interface AzureOfferState {
  readonly accountOwnerLiveId: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly registrationInfoError: boolean;
  readonly isRegistering: boolean;
  readonly isFetchingNpAzureRegistration: boolean;
  readonly registrationResultCode: AzureRegistrationResultCode;
  readonly isRenewing: boolean;
  readonly tokenValidationResult: AzureTokenValidationResult;
  readonly tokenValidationCode: AzureTokenValidationCode;
  readonly offerId: string;
  readonly statusId: number;
}

export enum AzureExpirationStatus {
  Expired = "Expired",
  Expiring = "Expiring",
  NotExpiring = "NotExpiring"
}

export enum RegistrationStatus {
  CanClaim="CanClaim",
  NotEligible="NotEligible",
  UnderReview="UnderReview",
  CanRenew="CanRenew",
  LegacyUpgrade="LegacyUpgrade"
}
export interface RegistrationError {
  title: string;
  code: AzureRegistrationResultCode;
  detail: string;
  status: number;
}

export interface RegistrationResponse {
  readonly startDate: string;
  readonly endDate: string;
  readonly offerId: string;
  readonly claimedByEmail: string;
  readonly registrationStatus: RegistrationStatus;
}

export interface AzureRegistrationState {
  loading: boolean;
  registration?: RegistrationResponse
  error?: RegistrationError;
  azureRenewalStatus?: AzureTransactionCode; // This will be undefined unless the user clicks renew when it will update to success or failure
}
