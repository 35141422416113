import "@ms-mwf/moray/dist/css/main.css";
import * as React from "react";
import StatusBanner, { StatusIcon } from "src/components/offers/shared/StatusBanner";
import Text from "src/components/text/Text";
import "./AzureRegistrationStatusBanner.scss";

export interface IAzureRegistrationStatusBannerProps {
  iconType: StatusIcon;
  grantStartDate?: string;
  grantEndDate?: string;
  statusTitle?: string;
  statusDescription: string;
  statusDescriptionSubstitutions?: { [key: string]: any };
  footerText?: string;
  claimedByEmail?: string;
}

const AzureRegistrationStatusBanner: React.FunctionComponent<IAzureRegistrationStatusBannerProps> = props => {
  const { iconType, statusTitle, claimedByEmail } = props;

  const showGrantDuration =
    props.grantStartDate != null &&
    props.grantStartDate != undefined &&
    props.grantEndDate != null &&
    props.grantEndDate != undefined;
  const showFooter = props.footerText != null && props.footerText != undefined;

  return (
    <StatusBanner iconType={iconType} text={statusTitle}>
      <div className="description-section row">
        <div className="description-content">
          <p id="description" className="description-text mb-0">
            <Text
              category="AzureV2"
              id={props.statusDescription}
              substitutionData={props.statusDescriptionSubstitutions}
            />
          </p>
          {showGrantDuration && (
            <p id="grantduration" className="description-text mb-0">
              <br />
              <b>
                <Text category="AzureV2" id="ClaimedBy" />
              </b>{" "}
              {claimedByEmail}
              <br />
              <b>
                <Text category="AzureV2" id="GrantStartDate" />
              </b>{" "}
              {props.grantStartDate}
              <br />
              <b>
                <Text category="AzureV2" id="GrantEndDate" />
              </b>{" "}
              {props.grantEndDate}
            </p>
          )}
          {showFooter && (
            <p className="description-text mb-0">
              <br />
              <Text category="AzureV2" id={props.footerText ?? ''} />
            </p>
          )}
        </div>
      </div>
    </StatusBanner>
  );
};

export default AzureRegistrationStatusBanner;
