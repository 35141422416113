import * as React from 'react';
import { connect } from 'react-redux';
import AzureRegistrationErrorMessageBanner from 'src/components/offers/azurev2/AzureRegistrationErrorMessageBanner';
import { StatusIcon } from 'src/components/offers/shared/StatusBanner';
import localeService from 'src/dataServices/LocaleService';
import { IApplicationState } from 'src/state';
import AzureRegistrationStatusBanner from './AzureRegistrationStatusBanner';
import { AzureExpirationStatus, RegistrationError, RegistrationResponse, RegistrationStatus } from 'src/state/offers/azure/types';
import { getAzureExpirationStatus, getRemainingDaysForAzureExpiration } from './Utils';
import { AzureTransactionCode } from './AzureTransactionCodes';

// Props for the AzureStatusBannerV2 component
export interface IAzureStatusBannerV2Props {
  renewalStatus?: AzureTransactionCode;
  azureRegistration?: RegistrationResponse;
  error?: RegistrationError;
}

// Banner Configuration Interface
interface BannerConfig {
  icon: StatusIcon;
  titleKey: string;
  descriptionKey: string;
  footerKey?: string;
  descriptionSubstitutions?: Record<string, string>;
  startDate?: string;
  endDate?: string;
  claimedByEmail?: string;
}

interface ErrorBannerConfig {
  titleKey: string;
  descriptionKey: string;
  messageCode?: string;
}

const AzureStatusBannerV2: React.FC<IAzureStatusBannerV2Props> = ({ renewalStatus, azureRegistration, error }) => {
  const { startDate, endDate, claimedByEmail, registrationStatus, } = azureRegistration || {};
  
  // Configuration for transaction banners
  const transactionBannerConfig = {
    GrantInitiated: {
      icon: StatusIcon.recent,
      titleKey: 'GrantInitiatedBannerTitle',
      descriptionKey: 'GrantInitiatedBannerDescriptionNew'
    },
    GrantClaimed: {
      icon: StatusIcon.success,
      titleKey: 'GrantClaimedBannerTitle',
      descriptionKey: 'GrantClaimedBannerDescriptionNew',
      footerKey: 'GrantClaimedBannerFooter'
    },
    AgentValidationUnderReview: {
      icon: StatusIcon.recent,
      titleKey: 'GrantClaimReviewTitle',
      descriptionKey: 'GrantClaimReviewDescription',
      footerText: 'GrantClaimReviewFooter'
    },
    AzureRenewalSuccess: {
      icon: StatusIcon.success,
      titleKey: 'GrantRenewedBannerTitle',
      descriptionKey: 'GrantRenewedBannerDescriptionNew'
    },
    CanRenewExpired: {
      icon: StatusIcon.warning,
      titleKey: 'GrantExpiredBannerTitle',
      descriptionKey: 'GrantExpiredBannerDescriptionNew',
      footerKey: 'GrantExpiredBannerFooterNew'
    },
    CanRenewExpiring: {
      icon: StatusIcon.recent,
      titleKey: 'GrantExpiringBannerTitle',
      descriptionKey: 'GrantExpiringBannerDescriptionNew',
      footerKey: 'GrantExpiringBannerFooter',
      descriptionSubstitutions: { remainingDays: String(getRemainingDaysForAzureExpiration(endDate ?? '')) }
    },
    IneligibleExpired: {
      icon: StatusIcon.warning,
      titleKey: 'GrantIneligibleExpiredTitle',
      descriptionKey: 'GrantIneligibleExpiredDescription',
    },
    IneligibleExpiring: {
      icon: StatusIcon.warning,
      titleKey: 'GrantIneligibleExpiringTitle',
      descriptionKey: 'GrantIneligibleExpiringDescription',
    }
  };

  const errorBannerConfig = {
    AgentValidationError: {
      titleKey: 'GrantClaimErrorTitle',
      descriptionKey: 'GrantClaimErrorDescription',
      messageCode: '920-123190'
    },
    AgentError: {
      titleKey: 'GrantClaimErrorTitle',
      descriptionKey: 'GrantClaimErrorDescription',
      messageCode: '715-123190'
    },
    AzureRenewalFailure: {
      titleKey: 'GrantRenewedErrorTitle',
      descriptionKey: 'GrantRenewedErrorDescription'
    },
    GenericError: {
      titleKey: 'GrantGenericErrorTitle',
      descriptionKey: 'GrantGenericErrorDescription'
    }
  };

  // Utility to create an error banner
  const createErrorBanner = (errorConfig: ErrorBannerConfig) => (
    <AzureRegistrationErrorMessageBanner
      statusTitle={localeService.getText('AzureV2', errorConfig.titleKey)}
      statusDescription={localeService.getText('AzureV2', errorConfig.descriptionKey) || ''}
      messageCode={errorConfig.messageCode}
    />
  );

  // Utility to create a status banner
  const createBanner = (config: BannerConfig) => (
    <AzureRegistrationStatusBanner
      grantStartDate={startDate ? localeService.getDateString(startDate) : undefined}
      grantEndDate={endDate ? localeService.getDateString(endDate) : undefined}
      claimedByEmail={claimedByEmail || ''}
      iconType={config.icon}
      statusTitle={localeService.getText('AzureV2', config.titleKey)}
      statusDescription={config.descriptionKey || ''}
      statusDescriptionSubstitutions={config.descriptionSubstitutions}
      footerText={config.footerKey}
    />
  );

  if (!azureRegistration || !endDate) {
    return null;
  }

  if (renewalStatus === AzureTransactionCode.AzureRenewalSuccess && registrationStatus !== RegistrationStatus.UnderReview) {
    return createBanner(transactionBannerConfig.AzureRenewalSuccess);
  };

  if (renewalStatus === AzureTransactionCode.AzureRenewalFailed) {
    return createErrorBanner(errorBannerConfig.AzureRenewalFailure);
  };

  const azureExpirationStatus = getAzureExpirationStatus(endDate);
  
  // can renew & expiring banner
  if (registrationStatus != undefined) {
    if (
      azureExpirationStatus === AzureExpirationStatus.Expiring &&
      [
        RegistrationStatus.CanRenew,
        RegistrationStatus.LegacyUpgrade,
      ].includes(registrationStatus)
    ) {
      return createBanner(transactionBannerConfig.CanRenewExpiring);
    }

    // can renew & expired banner
    else if (
      azureExpirationStatus === AzureExpirationStatus.Expired &&
      [RegistrationStatus.CanRenew, RegistrationStatus.LegacyUpgrade].includes(registrationStatus)
    ) {
      return createBanner(transactionBannerConfig.CanRenewExpired);
    }

    // ineligible and expired banner
    else if (azureExpirationStatus === AzureExpirationStatus.Expired && registrationStatus === RegistrationStatus.NotEligible) {
      return createBanner(transactionBannerConfig.IneligibleExpired);
    }

    // ineligible and expiring banner
    else if (azureExpirationStatus === AzureExpirationStatus.Expiring && registrationStatus === RegistrationStatus.NotEligible) {
      return createBanner(transactionBannerConfig.IneligibleExpiring);
    }

    // not expiring and not eligible banner (grant claimed)
    else if (azureExpirationStatus === AzureExpirationStatus.NotExpiring && registrationStatus === RegistrationStatus.NotEligible) {
      return createBanner(transactionBannerConfig.GrantClaimed);
    }

    // grant initiated
    else if (registrationStatus === RegistrationStatus.UnderReview) {
      return createBanner(transactionBannerConfig.GrantInitiated);
    }

    // Check if the registration state exists in the transaction states
    else if (registrationStatus in transactionBannerConfig) {
      const banner =
        transactionBannerConfig[RegistrationStatus[registrationStatus].toString() as keyof typeof transactionBannerConfig];
      return createBanner(banner as BannerConfig);
    }

    // Check if the registration state exists in the error states
    else if (error?.title ?? "" in errorBannerConfig) {
      const errorBanner =
        errorBannerConfig[RegistrationStatus[registrationStatus].toString() as keyof typeof errorBannerConfig];
      return createErrorBanner(errorBanner);
    }

    // Fallback to generic error banner if neither config contains the registration state
    else return createErrorBanner(errorBannerConfig.GenericError);
  }
  return createErrorBanner(errorBannerConfig.GenericError);
};

// Map state to props
const mapStateToProps = ({ offers }: IApplicationState) => ({
  azureRegistration: offers.azure.registration,
  renewalStatus: offers.azure.azureRenewalStatus,
  error: offers.azure.error
});

export default connect(mapStateToProps)(AzureStatusBannerV2);
