import { telemetryService } from 'npo-common';

const localeEnabledForV2 = (offer: string) => {
  const v2EnabledLocalesConfig = require('./v2-enabled-locale-config.json');
  const locale = telemetryService.getContext()['Locale'];
  const enabledLocales = v2EnabledLocalesConfig[offer];
  return enabledLocales !== null && enabledLocales !== undefined && enabledLocales.includes(locale);
};

export const enum LocaleConfigStrings {
  Azure = 'Azure',
  AccountGuard = 'AccountGuard',
  Microsoft365 = 'Microsoft365',
  Dynamic365 = 'Dynamic365',
  Hardware = 'Hardware',
  Linkedin = 'Linkedin',
  Cloud = 'Cloud',
  CloudSpring2023 = 'CloudSpring2023',
  Training = 'Training',
  Navigation = 'Navigation',
  Home = 'Home',
  Partners = 'Partners',
  Software = 'Software',
  Profile = 'Profile',
  BingAds = 'BingAds',
  CommunityTraining = 'CommunityTraining',
  M365CopilotOfferUpdate = 'M365CopilotOfferUpdate',
  D365CopilotOfferUpdate = 'D365CopilotOfferUpdate',
  MC4NPOfferUpdate = 'MC4NPOfferUpdate',
  M365CopilotDiscountOfferEnabled = 'M365CopilotDiscountOfferEnabled'
}

export default localeEnabledForV2;
