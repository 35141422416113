import { all, apply, fork, put, takeEvery } from "redux-saga/effects";
import { IApiResponse } from "src/common/ApiResponse";
import azureRegistrationService from "src/dataServices/AzureRegistrationService";
import { ActionType } from "typesafe-actions";
import {
  initiateAzureRegistration,
  initiateAzureRegistrationForOfferId,
  initiateAzureRenewal,
  resetAzureRegistrationForOfferId,
  setAzureRegistrationInfo,
  setAzureRegistrationError,
  setAzureRegistrationResult,
  setAzureTokenValidationResult,
  setAzureRenewalStatus
} from "./actions";
import {
  AzureOfferActionTypes,
  AzureRegistrationInfo,
  AzureRegistrationResult,
  AzureRegistrationResultCode,
  AzureTokenValidationCode,
  AzureTokenValidationResult,
  RegistrationError,
  RegistrationResponse
} from "./types";
import { AzureTransactionCode } from "src/components/offers/azurev2/AzureTransactionCodes";

/**
 * Submit the form values provided by the user to apply for a new Azure registration.
 * @param action object whose payload contains the form values
 */
export function* handleInitiateAzureRegistration(action: ActionType<typeof initiateAzureRegistration>) {
  const formValues = action.payload;
  const response: IApiResponse<RegistrationResponse> = yield apply(
    azureRegistrationService,
    azureRegistrationService.createRegistration,
    [formValues]
  );
  if (response.status === 200) {
    yield put(setAzureRegistrationInfo(response.value));
  } else {
    yield put(setAzureRegistrationError(response.error));
  }
}

/**
 * Creates a new Azure Registration with the provided offer id.
 * @param action object whose payload contains the offerId.
 */
export function* handleInitiateAzureRegistrationForOfferId(
  action: ActionType<typeof initiateAzureRegistrationForOfferId>
) {
  const formValues = action.payload;
  const response: IApiResponse<RegistrationResponse> = yield apply(
    azureRegistrationService,
    azureRegistrationService.createRegistrationForOfferId,
    [formValues]
  );
  if (response.status === 200) {
    yield put(setAzureRegistrationInfo(response.value));
  } else {
    yield put(setAzureRegistrationError(response.error));
  }
}

/**
 * Resets existing Azure Registration with the provided offer id.
 * @param action object whose payload contains the offerId.
 */
export function* handleResetAzureRegistrationForOfferId(action: ActionType<typeof resetAzureRegistrationForOfferId>) {
  const formValues = action.payload;
  const response: IApiResponse<RegistrationResponse> = yield apply(
    azureRegistrationService,
    azureRegistrationService.resetRegistrationForOfferId,
    [formValues]
  );
  if (response.status === 200) {
    yield put(setAzureRegistrationInfo(response.value));
  } else {
    yield put(setAzureRegistrationError(response.error));
  }
}

export function* handleInitiateAzureRenewal(action: ActionType<typeof initiateAzureRenewal>) {
  const token = action.payload;
  const response: IApiResponse<RegistrationResponse> = yield apply(
    azureRegistrationService,
    azureRegistrationService.renewRegistration,
    [token]
  );
  if (response.status === 200) {
    yield put(setAzureRenewalStatus(AzureTransactionCode.AzureRenewalSuccess))
    yield put(setAzureRegistrationInfo(response.value));
  } else {
    yield put(setAzureRenewalStatus(AzureTransactionCode.AzureRenewalFailed))
    yield put(setAzureRegistrationError(response.error));
  }
}

/**
 * Get any existing Azure registrations for the current user and dispatch an
 * action to reflect this existence.
 */
export function* handleFetchAzureRegistrationInfo() {
  const response: IApiResponse<RegistrationResponse> = yield apply(
    azureRegistrationService,
    azureRegistrationService.getActiveRegistrations,
    []
  );

  if (response.status === 200) {
    yield put(setAzureRegistrationInfo(response.value));
  } else {
    yield put(setAzureRegistrationError(response.error));
  }
}

function* watchFetchAzureRegistrationInfo() {
  yield takeEvery(AzureOfferActionTypes.FETCH_REGISTRATION_INFO, handleFetchAzureRegistrationInfo);
}

function* watchInitiateAzureRegistration() {
  yield takeEvery(AzureOfferActionTypes.INITIATE_REGISTRATION, handleInitiateAzureRegistration);
}

function* watchInitiateAzureRegistrationForOfferId() {
  yield takeEvery(AzureOfferActionTypes.INITIATE_REGISTRATION_FOR_OFFER_ID, handleInitiateAzureRegistrationForOfferId);
}

function* watchResetAzureRegistrationForOfferId() {
  yield takeEvery(AzureOfferActionTypes.RESET_REGISTRATION_FOR_OFFER_ID, handleResetAzureRegistrationForOfferId);
}

function* watchInitiateAzureRenewal() {
  yield takeEvery(AzureOfferActionTypes.INITIATE_RENEWAL, handleInitiateAzureRenewal);
}

function* azureOfferSaga() {
  yield all([
    fork(watchInitiateAzureRegistration),
    fork(watchInitiateAzureRegistrationForOfferId),
    fork(watchResetAzureRegistrationForOfferId),
    fork(watchFetchAzureRegistrationInfo),
    fork(watchInitiateAzureRenewal)
  ]);
}

export default azureOfferSaga;
